import React from 'react';
import { Route, Switch, Redirect} from 'react-router-dom';
import asyncComponent from '../../itrust_common/components/AsyncComponent';

const Dashboard = asyncComponent(() =>
  import('../../containers/authorised/dashboard/Dashboard.js')
);


const DemoRequest = asyncComponent(()=>
  import('../../containers/authorised/demoRequest/DemoRequest')
)


const DashboardRoutes = (
  <Switch>
    <Route exact path="/dashboard" component={Dashboard} />
    <Route exact path="/dashboard/demo-requests" component={DemoRequest} />
    <Redirect exact from="/" to="/dashboard"/>
  </Switch>
);

export default DashboardRoutes;