import React from 'react';
import DemoRequestRecived from "./wrappers/DemoRequestRecived" 

let NotificationView = props => {
  const {
    activity
  } = props;

  const renderNotification = ()=> {
    switch (activity.activity_type) {
      case 'patient_sms_received':
        return <DemoRequestRecived activity={activity} />;
      default:
        return activity.breed;
    }
  }
    
  return(
    <span>
      {renderNotification()}
    </span>
  )
};

export default NotificationView;
