import React, { Component } from 'react';
import { connect } from 'react-redux';
import ActionCable from 'actioncable';
import { WS_ROOT } from '../../../env.js';
import { humanize } from '../../../itrust_common/components/HelperFunctions';
import NotificationView from '../../../components/authorised/notification/NotificationView';
import { notificationFilterChange, notificationsLoad, notificationCreate } from '../../../itrust_common/redux/actions/notificationActions';


class Notification extends Component {
  constructor(props) {
    super(props);
    this.state = {
      prevY: 0
    }
    props.dispatch(notificationsLoad({...props.filters, include: ['activity']}));
    this.handleActionCableCreate()
  }

  handleActionCableCreate(){
    let _this = this;
    setTimeout(function () {
      var cable = ActionCable.createConsumer(_this.getWebSocketURL());
      cable.subscriptions.create(
        {
          channel: 'NotificationsChannel',
        },
        {
          connected: function () {
            console.log('Socket connected');
          },
          disconnected: function () {
            console.log('Socket disconnected');
          },
          received: function (data) {
            _this.props.dispatch(notificationCreate(data));
            console.log('notification received');
          },
          speak: function (message) {
            return this.perform('speak', {
              message: message
            });
          }
        }
      );
    }, 4000)
  }

  getWebSocketURL() {
    const token = localStorage.getItem('Access-Token')
    return `${WS_ROOT}/cable?token=${token}`
  }

  handleObserver(entities, observer) {
    const { notifications, filters, totalRecords } = this.props
    const y = entities[0].boundingClientRect.y;
    if ((this.state.prevY > y) && (notifications.length < totalRecords)) {
      var newFilters = {...filters, page: filters.page+1}
      this.handleNotificationsLoad(newFilters);
    }
    this.setState({prevY: y})
  }

  componentDidMount() {
    var options = {
      root: document.querySelector("#infinte-notification-container"),
      rootMargin: '0px',
      threshold: 0.1,
      delay: 10
    };
    this.observer = new IntersectionObserver(
      this.handleObserver.bind(this),
      options
    );
    const target = document.querySelector("#infinte-notification-target")
    this.observer.observe(target);
  }

  handleNotificationsLoad(filters) {
    this.props.dispatch(notificationsLoad({ activity_type: "demo_request_received", ...filters, include: ['activity']}));
    this.props.dispatch(notificationFilterChange({filters: filters}));
  }
 
  render() {
    const { notifications, unread_count, filters, loading } = this.props;
    return (
      <div className="px-5 px-md-6 px-lg-7 notification text-right ">
        <input
          className="notifications-input"
          onFocus={() => this.handleNotificationsLoad({ read: 'true', ...filters, page: 1, rpp: 10})}
          id="notifications-input"
        />
        <label data-toggle="dropdown" htmlFor="notifications-input">
          <i className="la la-bell text-muted" />
          {unread_count > 0 && (
            <small className="circle red text-white">
              {unread_count}
            </small>
          )}
        </label>
        <ul className="dropdown-menu dropdown-menu-right shadow border-0 rounded" id="infinte-notification-container">
          {unread_count > 0 &&
            <li className="border-bottom border-bottom-2 border-light px-5 py-4">
              <div className="d-flex flex-row align-items-center justify-content-between">
                <span className="">You have {unread_count} new notifications.</span>
                <button className="btn btn-sm btn-link" onClick={() => this.handleNotificationsLoad({ read: 'all', ...filters, page: 1, rpp: 10})}>Mark all as read</button>
              </div>
            </li>
          }
          <li>
            <ul className="list-unstyled notification-list overflow-auto" id="scroller">
              {notifications.map((notification, index) => (
                <li key={'notification-' + notification.id} className="border-bottom border-bottom-2 border-light px-5 py-5">
                  <div className="d-flex flex-row align-items-center justify-content-between mb-2">
                    <h6 className="">{humanize(notification.activity_attributes.activity_type)}</h6>
                    <span className="text-muted">{notification.created_at}</span>
                  </div>
                  <NotificationView activity={ notification.activity_attributes } index={ index }/>
                </li>
              ))}
              <li id="infinte-notification-target" className="text-center px-5 py-3">
                { loading && <div className="spinner-border text-primary" role="status">
                  <span className="sr-only">Loading...</span>
                </div> }
              </li>
            </ul>
          </li>
          <li className="text-center px-5 py-3">
            <span>Scroll down to view more <i className="fa fa-angle-down" /></span>
          </li>
        </ul>
      </div>
    );
  }
}
function mapStateToProps(state) {
  const { notification, auth } = state;
  return {
    notifications: notification.notifications,
    loading: notification.saving,
    totalRecords: notification.meta.pagination.count,
    meta: notification.meta,
    unread_count: notification.meta.unread_count,
    filters: notification.filters,
    currentUser: auth.user,
  };
}

export default connect(mapStateToProps)(Notification);
