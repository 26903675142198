import Toastr from "../../itrust_common/components/Toastr"
const DEFAULT_STATE = {
  organisations: [],
  organisation: {},
  meta: {pagination: {}},
  saving: false
}
let changes = null;
export default function organisationReducer(state = DEFAULT_STATE, action){
  const response = action.payload && (action.payload.data || action.payload.response)
  let organisations = [];
  let organisationData = [];
  let updatedRecord = {};
  switch(action.type){
    case 'ORGANISATIONS_LOAD_PENDING':
    case 'ORGANISATION_LOAD_PENDING':
    case 'MANAGE_ADD_ON_UPDATE_PENDING':
      return {...state, saving: true};

    case 'ORGANISATION_CREATE_PENDING':
    case 'ORGANISATION_UPDATE_PENDING':
      return {...state, saving: true};

    case 'ORGANISATIONS_LOAD_REJECTED':
    case 'ORGANISATION_LOAD_REJECTED':
    case 'ORGANISATION_CREATE_REJECTED':
    case 'ORGANISATION_UPDATE_REJECTED':
    case 'MANAGE_ADD_ON_UPDATE_REJECTED':
      Toastr.handleShow(response.data.success, response.data.error, response.data.status_code)
      changes = {
        saving: false
      }
      return {...state,...changes};

    case 'ORGANISATIONS_LOAD_FULFILLED':
      changes = {
        ...response,
        saving: false
      };
      return {...state, ...changes};
    
    case 'ORGANISATION_LOAD_FULFILLED':
      changes = {
        organisation: response.organisation,
        saving: false
      }
      return {...state,...changes};
    
    case 'ORGANISATION_CREATE_FULFILLED':
      Toastr.handleShow(true, response.meta.message, 201)
      organisations = [...state.organisations]
      let newRecord = response.organisation
      changes = {
        organisations: [...organisations, newRecord],
        editing: false,
        saving: false
      }
      return {...state, ...changes};

    case 'ORGANISATION_UPDATE_FULFILLED':
      Toastr.handleShow(true, response.meta.message, 201)
      organisations = [...state.organisations];
      updatedRecord = response.organisation;
      for (const [index, organisation] of organisations.entries()) {
        if (organisation.id === updatedRecord.id) {
          organisationData = [
            ...organisations.slice(0, index),
            updatedRecord,
            ...organisations.slice(index + 1)
          ];
        }
      }
      changes = {
        organisations: organisationData,
        organisation: updatedRecord,
        editing: false,
        saving: false
      };
      return { ...state, ...changes };

    case 'ORGANISATION_NEW_FROM_DEMO_REQUEST':
      changes = {
        ...response,
        saving: false
      }
      return {...state,...changes};

    case 'ORGANISATION_EDIT':
      changes = {
        editing: {
          id: action.id,
          action: action.action,
        },
        organisation: { address_attributes: {country: "United States"} }
      };
      return { ...state, ...changes };

    case 'ORGANISATION_EDIT_CANCEL':
      changes = {
        editing: false
      };
      return { ...state, ...changes };

    case 'MANAGE_ADD_ON_UPDATE_FULFILLED':
      Toastr.handleShow(true, response.meta.message, 201)
      updatedRecord = response.organisation;
      changes = {
        organisation: updatedRecord,
        saving: false
      };
      return { ...state, ...changes };

    default:
      return state;
  }
}