import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import asyncComponent from '../../itrust_common/components/AsyncComponent';

const Organisations = asyncComponent(()=>
  import('../../containers/authorised/organisation/Organisations.js')
);

const Highlights = asyncComponent(()=>
  import('../../containers/authorised/organisation/Highlights.js')
);
const Stores = asyncComponent(()=>
  import('../../containers/authorised/organisation/stores/Stores.js')
);
const Staff = asyncComponent(()=>
  import('../../containers/authorised/organisation/staff/Staff.js')
)

const AddOnsWrapper = asyncComponent(() =>
  import('../../containers/authorised/organisation/addOns/AddOnsWrapper.js')
)

const OrganisationRoutes = (
  <Switch>
    <Route exact path="/organisations" component={Organisations} />
    <Route exact path="/organisations/:id/highlights" component={Highlights} />
    <Route exact path="/organisations/:id/stores" component={Stores} />
    <Route exact path="/organisations/:id/staff" component={Staff} />
    <Route exact path="/organisations/:id/add-ons" component={AddOnsWrapper} />
    <Redirect exact from="/organisations/:id" to="/organisations/:id/highlights" />
  </Switch>
);


export default OrganisationRoutes;