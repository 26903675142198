import React, { Component } from "react";
import {connect} from 'react-redux';
import "./assets/scripts";
import { BrowserRouter as Router } from 'react-router-dom';
import PublicRoute from './routes/public/authRoutes.js';
import AuthorisedRoutes from './routes/authorised';
import Toastr from './itrust_common/components/Toastr';
import Alert from './itrust_common/components/Alert';
import NewVersionPopUp from './itrust_common/components/others/NewVersionPopUp';
import { currentUserLoad, logout } from './itrust_common/redux/actions/authActions';
import { getBackground, removeBackground } from './itrust_common/components/HelperFunctions'

class App extends Component {
  constructor(props) {
    super(props);
    if(localStorage.getItem('Access-Token')){
      this.currentUserLoad()
    }
  }

  currentUserLoad() {
    this.props.dispatch(currentUserLoad())
  }

  handleLogout() {
    this.props.dispatch(logout())
  }

  componentDidUpdate() {
    if(this.props.currentUser !==null){
      setTimeout(function(){
        getBackground(this.props.currentUser)
      }, 500)
    }
    else removeBackground()
  }

  render() {
    const { appFontSize } = this.props
    const isLogged = localStorage.getItem('Access-Token') ? true : false;
    return (
      <div className={`app ${appFontSize === "large" && 'large-font'}`}>
        <Toastr/>
        <Alert/>
        <NewVersionPopUp />
        <Router>
          <div className="h-100">
            {isLogged ? (
              <AuthorisedRoutes handleLogout={this.handleLogout.bind(this)}/>
            ) : (
              <div className="h-100">
                <PublicRoute />
              </div>
            )}
          </div>
        </Router>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const { auth } = state;
  return {
    appFontSize: auth.user && auth.user.font_size,
    currentUser: auth.user,
    currentDayHour: auth.currentDayHour
  }
}

export default connect(mapStateToProps)(App);
