import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import asyncComponent from '../../itrust_common/components/AsyncComponent';

const AdminLogin = asyncComponent(() => 
  import ('../../containers/public/auth/AdminLogin.js')
);

const authRoute = () => {
  return (
    <Switch>
      <Route exact path="/" component={AdminLogin} />
      <Redirect from="*" to="/"/>
    </Switch>
  );
}

export default authRoute;