import React from 'react';
let DemoRequestRecived = props => {
  const {
    activity,
  } = props;
  return (
    <p className="mb-0">{activity.parameters.contact_name} has sent you a demo request. to see the detail click on
      <span className="btn btn-sm btn-link p-0 align-top ml-1">view</span>.
    </p>
  );
};

export default DemoRequestRecived;
