import React from 'react';
import { NavLink } from 'react-router-dom';
import BackgroundSelector from '../../../itrust_common/components/others/BackgroundSelector';
import ThemePicker from '../../../itrust_common/components/others/ThemePicker.js';

const Sidebar = (props) => {
  const { sidebarMenu } = props
  return (
    <div>
      <div className="menubar bg-white text-center position-fixed h-100 border-right">
        { sidebarMenu.map(item => (
          <NavLink key={item.path} to={item.path} activeClassName="active">
            <div className="py-3 mb-5">
              <i className={`la ${item.icon} menu-icon`} />
              <h6 className="menu-title pt-1">{item.title}</h6>
            </div>
          </NavLink> ))
        }
        <ThemePicker/>
      </div>
      <BackgroundSelector/>
    </div>
  );
};

export default Sidebar;
