import React from 'react';
import { Route, Switch, Redirect} from 'react-router-dom';
import asyncComponent from '../../../itrust_common/components/AsyncComponent';

const TimeOfDayPhoto = asyncComponent(() =>
  import('../../../containers/authorised/appSetting/customizeBackground/TimeOfDayPhoto')
)
const SeasonPhoto = asyncComponent(() =>
  import('../../../containers/authorised/appSetting/customizeBackground/SeasonPhoto')
)

const CustomizeBackgroundRoutes = (
  <Switch>
    <Route exact path="/app-settings/time-of-day-photo" component={TimeOfDayPhoto} />
    <Route exact path="/app-settings/season-photo" component={SeasonPhoto} />
    <Redirect exact from="/app-settings" to="/app-settings/time-of-day-photo" />
  </Switch>
);

export default CustomizeBackgroundRoutes;