import Toastr from "../../itrust_common/components/Toastr"

const DEFAULT_STATE = {
  demo_requests: [],
  demo_request: {},
  meta: {pagination: {}},
  saving: false
}
let changes=null
export default function demoRequestReducer(state=DEFAULT_STATE, action){
  const response = action.payload && (action.payload.data || action.payload.response)
  let demoRequests = [];
  let demoRequestData = [];
  let updatedRecord = {};
  switch(action.type){
    case 'DMEO_REQUESTS_LOAD_PENDING':
      return {...state, saving: true};

    case 'DEMO_REQUESTS_LOAD_REJECTED':
      changes = {
        saving: false
      }
      return {...state,...changes}; 
    case 'DEMO_REQUESTS_LOAD_FULFILLED':
      changes = {
        ...response,
        saving: false
      }
      return {...state, ...changes}
    
    case 'DEMO_REQUEST_UPDATE_FULFILLED':
      demoRequests = [...state.demo_requests];
      updatedRecord = response.demo_request;
      for (const [index, demo_request] of demoRequests.entries()) {
        if (demo_request.id === updatedRecord.id) {
          demoRequestData = [
            ...demoRequests.slice(0, index),
            updatedRecord,
            ...demoRequests.slice(index + 1)
          ];
        }
      }
      changes = {
        demo_requests: demoRequestData,
        editing: false
      };
      return { ...state, ...changes };

      case 'DEMO_REQUEST_DELETE_FULFILLED':
        Toastr.handleShow(true, response.meta.message, 201)
        demoRequests = [...state.demo_requests];
        for (const [index, demo_request] of demoRequests.entries()) {
          if (demo_request.id === response.demo_request.id) {
            demoRequests.splice(index, 1);
          }
        }
        return {
          ...state,
          demo_requests: demoRequests,
        };

    default: 
      return state   
  }
}