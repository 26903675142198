import React from 'react';
import { Route, Switch } from 'react-router-dom';
import asyncComponent from '../../itrust_common/components/AsyncComponent';

const Payments = asyncComponent(() =>
  import('../../containers/authorised/paymentSetting/Payements.js')
);

const PaymentsRoutes = (
  <Switch>
    <Route exact path="/payment/coupons" component={Payments} />
  </Switch>
);

export default PaymentsRoutes;