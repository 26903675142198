import { combineReducers} from 'redux';

import { reducer as formReducer} from 'redux-form';
import organisationReducer from '../redux/reducers/organisationReducer';
import staffReducer from './reducers/staffReducer';
import paymentReducer from './reducers/paymentReducer';
import storeReducer from './reducers/storeReducer';
import demoRequestReducer from '../redux/reducers/demoRequestReducer';
import itrustCommonReducer from '../itrust_common/redux/reducers';


const rootReducer = combineReducers({
  demoRequest: demoRequestReducer, 
  organisation: organisationReducer,
  staff: staffReducer,
  payment: paymentReducer,
  store: storeReducer,
  ...itrustCommonReducer,
  form: formReducer,
});

export default rootReducer;