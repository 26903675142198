import Toastr from "../../itrust_common/components/Toastr"
const DEFAULT_STATE = {
  staffs: [],
  staff: {},
  meta: { pagination: {} },
  saving: false
}
let changes = null;
export default function staffReducer(state = DEFAULT_STATE, action){
  const response = action.payload && (action.payload.data || action.payload.response)
  let staffs = [];
  let staffData = [];
  let updatedRecord = {};
  switch(action.type){
    case 'STAFFS_LOAD_PENDING':
    case 'STAFF_LOAD_PENDING':
      return {...state, saving: true};

    case 'STAFF_CREATE_PENDING':
    case 'STAFF_UPDATE_PENDING':
      return {...state, saving: true};

    case 'STAFFS_LOAD_REJECTED':
    case 'STAFF_LOAD_REJECTED':
    case 'STAFF_CREATE_REJECTED':
    case 'STAFF_UPDATE_REJECTED':
      Toastr.handleShow(response.data.success, response.data.error, response.data.status_code)
      changes = {
        saving: false
      }
      return {...state,...changes};

    case 'STAFFS_LOAD_FULFILLED':
      changes = {
        ...response,
        saving: false
      };
      return {...state, ...changes};
    
    case 'STAFF_LOAD_FULFILLED':
      changes = {
        staff: response.staff,
        saving: false
      }
      return {...state,...changes};
    
    case 'STAFF_CREATE_FULFILLED':
      Toastr.handleShow(true, response.meta.message, 201)
      staffs = [...state.staffs]
      let newRecord = response.staff
      changes = {
        staffs: [...staffs, newRecord],
        editing: false,
        saving: false
      }
      return {...state, ...changes};

    case 'STAFF_UPDATE_FULFILLED':
      Toastr.handleShow(true, response.meta.message, 201)
      staffs = [...state.staffs];
      updatedRecord = response.staff;
      for (const [index, staff] of staffs.entries()) {
        if (staff.id === updatedRecord.id) {
          staffData = [
            ...staffs.slice(0, index),
            updatedRecord,
            ...staffs.slice(index + 1)
          ];
        }
      }
      changes = {
        staffs: staffData,
        editing: false,
        saving: false
      };
      return { ...state, ...changes };

    case 'STAFF_DELETE_FULFILLED':
      Toastr.handleShow(true, response.meta.message, 201)
      staffs = [...state.staffs];
      for (const [index, staff] of staffs.entries()) {
        if (staff.id === response.staff.id) {
          staffs.splice(index, 1);
        }
      }
      return {
        ...state,
        staffs: staffs,
        error: null
      };

    case 'STAFF_EDIT':
      changes = {
        editing: {
          id: action.id,
        },
        staff: { address_attributes: {country: "United States"} } 
      };
      return { ...state, ...changes };

    case 'STAFF_EDIT_CANCEL':
      changes = {
        editing: false
      };
      return { ...state, ...changes };

    default:
      return state;
  }
}