import React, { Component} from 'react';
import Header from '../../itrust_common/components/authorised/layouts/Topbar.js';
import Sidebar from '../../components/authorised/layouts/Sidebar.js';
import DashboardRoutes from './dashboardRoutes.js';
import OrganisationRoutes from './organisationRoutes.js';
import PaymentsRoutes from './paymentRoutes';
import ActivityRoutes from '../../itrust_common/routes/authorised/activityRoutes';
import { SettingExamRoutes, SettingMessageTemplateRoutes, SettingOrderRoutes, SettingPatientRoutes, SettingContactRoutes, SettingCalendarRoutes, SettingInsuranceRoutes } from '../../itrust_common/routes/authorised/setting/index';
import {AppSettingCustomizeBackgroundRoutes} from './appSetting/index';

const headerMenu = [];

const sidebarMenu = [{title:'Dashboard',icon:'la-dashboard',path:'/dashboard'},
                    {title:'Organizations',icon:'la-hospital-o',path:'/organisations'},
                    {title:'Activity',icon:'la-bar-chart',path:'/activities'},
                    { title: 'Payment Settings', icon: 'la-dollar', path:'/payment/coupons'},
                    {title:'Settings',icon:'la-gears',path:'/settings'},
                    {title:'App Settings',icon:'la-gear',path:'/app-settings'}];

class Authorised extends Component{
  render(){
    return(
      <div className="">
        <Header headerMenu={headerMenu} handleLogout={this.props.handleLogout}/>
        <div className="main-div">
          <Sidebar sidebarMenu={sidebarMenu}/>
          <div className="main-content position-relative px-5 bg-light">
            {DashboardRoutes}
            {OrganisationRoutes}
            {ActivityRoutes}
            {PaymentsRoutes}
            {SettingExamRoutes}
            {SettingMessageTemplateRoutes}
            {SettingOrderRoutes}
            {SettingPatientRoutes}
            {SettingContactRoutes}
            {SettingCalendarRoutes}
            {SettingInsuranceRoutes}
            {AppSettingCustomizeBackgroundRoutes}
          </div>
        </div>
      </div>
    );
  }
}

export default Authorised;
