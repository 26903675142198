import Toastr from "../../itrust_common/components/Toastr";

const DEFAULT_STATE = {
  coupons: [],
  coupon: {},
  meta: { pagination: {} },
  saving: false
}

let changes = null;
export default function paymentReducer(state = DEFAULT_STATE, action) {
  const response = action.payload && (action.payload.data || action.payload.response)
  let coupons = [];
  // let coupon = {};
  // let couponData = [];
  // let updatedRecord = {};

  switch (action.type) {
    case "COUPONS_LOAD_PENDING":
      return { ...state, saving: true };

    case "COUPON_LOAD_PENDING":
      return { ...state, saving: true };

    case 'COUPON_CREATE_PENDING':
      return { ...state, saving: true };

    case "COUPONS_LOAD_FULFILLED":
      changes = {
        coupons: response.coupons.data,
        saving: false
      }
      return { ...state, ...changes };

    case "COUPON_LOAD_FULFILLED":
      changes = {
        coupon: response.coupon,
        saving: false
      }
      return { ...state, ...changes };

    case 'COUPON_CREATE_FULFILLED':
      Toastr.handleShow(true, response.meta.message, 201)
      coupons = [...state.coupons]
      let newRecord = response.create_coupon
      changes = {
        coupons: [...coupons, newRecord],
        editing: false,
        saving: false
      }
      return { ...state, ...changes };

    case "COUPON_DELETE_FULFILLED":
      Toastr.handleShow(true, response.meta.message, 201)
      coupons = [...state.coupons];
      for (const [index, coupon] of coupons.entries()) {
        if (coupon.id === response.coupon.id) {
          coupons.splice(index, 1);
        }
      }
      return { ...state, coupons: coupons, error: null };

    case 'COUPON_CREATE_REJECTED':
      Toastr.handleShow(response.data.success, response.data.error, response.data.status_code)
      changes = {
        saving: false
      }
      return { ...state, ...changes };

    case 'COUPON_EDIT_CANCEL':
      changes = {
        editing: false
      };
      return { ...state, ...changes };

    default:
      return state
  }
}
