import React from 'react'
import { connect } from 'react-redux'
import InputDropdown from './InputDropdown'
import Select from './Select'

function ConditionalDropdown(props) {
  const { orgSubdomain } = props
  const supportOldUIOrg = ['reginesmetod', 'ismartvisioncare', 'noyngood', 'manahawkinvision']
  return supportOldUIOrg.includes(orgSubdomain) ? <Select {...props} className="pl-3" /> : <InputDropdown {...props} />;
}

const mapStateToProps = (state) => {
  const { organisation: { organisation } } = state
  return {
    orgSubdomain: organisation.subdomain
  }
}

export default connect(mapStateToProps)(ConditionalDropdown)