import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import App from './App';
import * as serviceWorker from './serviceWorker';
import state from './redux/store.js';
import { ClearCacheProvider } from 'react-clear-cache';

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={state}>
    <ClearCacheProvider duration={900000}>
      <App />
    </ClearCacheProvider>,
  </Provider>);

serviceWorker.register({
  onUpdate: registration => {
    // alert('You are accessing old version of iTrust software. An update is available close the browser tab and open website URL again to reflect changes.');
    if (registration && registration.waiting) {
      registration.waiting.postMessage({ type: 'SKIP_WAITING' });
    }
    window.location.reload();
  }
});
