import React, {Component} from 'react';
import {connect} from 'react-redux';
import color_plate from '../../assets/static/images/color-plate.png';
import * as $ from 'jquery';
import { unsplashPhotosLoad } from '../../redux/actions/UnsplashActions';
import { currentUserUpdate } from '../../redux/actions/authActions';

const blue="#297ebb", pink="#68c031", orange = "#efa03e", light_blue = "#a458ef", purple = "#ef39e5", yellow = "#7acdef", light_yellow = "#efc348", black = "#000000", red = "#ff0000"

const colorPlates = [{value:'theme1',color: blue}, {value:'theme2',color: pink}, {value:'theme3',color: orange},
                      {value:'theme4',color: light_blue}, {value:'theme5',color: purple}, {value:'theme6',color: yellow}, {value:'theme7',color: light_yellow}, {value:'theme8',color: black}, {value:'theme9',color: red}];
  
class ThemePicker extends Component{
  
  handleTheme(themeName){
    localStorage.setItem('theme', themeName);
    window.location.reload()
  }
  handleCurrentUserUpdate(data){
    this.props.dispatch(currentUserUpdate(data))
  }
  handleFontSizeUpdate(data) {
    const FontSize = { font_size: data }
    this.props.dispatch(currentUserUpdate(FontSize))
  }
  handleBackground(categoryName){
    this.props.dispatch(unsplashPhotosLoad({query: 'sea', page: 1}, categoryName))
    this.handleCurrentUserUpdate({background_mode: 'custom'})
    $('.customise-background').modal('show')
  }

  render() {
    const { backgroundMode, appFontSize } = this.props
    return (
      <>
      <div className="dropup theme-icon">
        <button className="btn badge badge-primary badge-pill" data-toggle="dropdown"><img src={color_plate} alt="plate"/></button>
        <div className="dropdown-menu shadow border-0 rounded text-center">
          <small>Theme Apperence</small>
          <p>
            { colorPlates.map(item => (
              <button onClick={this.handleTheme.bind(this, item.value)} value={`${item.value}`} className="btn" key={item.color}><i className="la la-adjust" style={{color: `${item.color}`}}/></button>))}
          </p>
          <hr className="text-light"/>
          <small>Customize Background</small>
          <div className="d-flex flex-column customize-background text-left mt-3">
            <small className={`my-2 px-5 pointer ${backgroundMode==="time_of_day" && 'active'}`} onClick={this.handleCurrentUserUpdate.bind(this, {background_mode: 'time_of_day'})}>
              <i className="la la-sun-o mx-2"/>Set to Time of Day</small>
            <small className={`my-2 px-5 pointer ${backgroundMode==="season" && 'active'}`} onClick={this.handleCurrentUserUpdate.bind(this, {background_mode: 'season'})}>
              <i className="la la-umbrella mx-2"/>Set to Season</small>
            <small className={`my-2 px-5 pointer ${backgroundMode==="custom" && 'active'}`} onClick={this.handleBackground.bind(this,"user")}>
              <i className="la la-upload mx-2"/>Wallpaper</small>
            <small className={`my-2 px-5 ${backgroundMode==="default" ? 'disable text-muted' : 'pointer'}`} onClick={this.handleCurrentUserUpdate.bind(this,{background_mode: 'default'})}>
              <i className="la la-history mx-2"/>Restore Default</small>
            </div>
          <hr className="text-light" />
          <small>Font Apperence</small>
          <div>
            <div className={`btn-group btn-group-toggle btn-group-sm w-60 my-3`}>
              {[{ label: 'Default', value: 'default' }, { label: 'Large', value: 'large' }].map((item) =>
                <label className={`btn btn-outline-primary ${appFontSize === item.value && 'active'} `} onClick={(e) => this.handleFontSizeUpdate(item.value)} key={item.value}>
                  <input value={'default'} type="radio" />
                  {item.label}
                </label>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
    );
  }
};

const mapStateToProps = state => {
  const { user } = state.auth
  return {
    backgroundMode: user.background_mode,
    appFontSize: user && user.font_size,
  }
}
export default connect(mapStateToProps)(ThemePicker);
