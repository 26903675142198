import Toastr from "../../itrust_common/components/Toastr"
const DEFAULT_STATE = {
  stores: [],
  store: {},
  meta: {pagination: {}},
  saving: false
}
let changes = null;
export default function storeReducer(state = DEFAULT_STATE, action){
  const response = action.payload && (action.payload.data || action.payload.response)
  let stores = [];
  let storeData = [];
  let updatedRecord = {};
  switch(action.type){
    case 'STORES_LOAD_PENDING':
    case 'STORE_LOAD_PENDING':
      return {...state, saving: true};

    case 'STORE_CREATE_PENDING':
    case 'STORE_UPDATE_PENDING':
      return {...state, saving: true};

    case 'STORES_LOAD_REJECTED':
    case 'STORE_LOAD_REJECTED':
    case 'STORE_CREATE_REJECTED':
    case 'STORE_UPDATE_REJECTED':
      Toastr.handleShow(response.data.success, response.data.error, response.data.status_code)
      changes = {
        saving: false
      }
      return {...state,...changes};

    case 'STORES_LOAD_FULFILLED':
      changes = {
        ...response,
        saving: false
      };
      return {...state, ...changes};
    
    case 'STORE_LOAD_FULFILLED':
      changes = {
        store: response.store,
        saving: false
      }
      return {...state,...changes};
    
    case 'STORE_CREATE_FULFILLED':
      Toastr.handleShow(true, response.meta.message, 201)
      stores = [...state.stores]
      let newRecord = response.store
      changes = {
        stores: [...stores, newRecord],
        editing: false,
        saving: false
      }
      return {...state, ...changes};

    case 'STORE_UPDATE_FULFILLED':
      Toastr.handleShow(true, response.meta.message, 201)
      stores = [...state.stores];
      updatedRecord = response.store;
      for (const [index, store] of stores.entries()) {
        if (store.id === updatedRecord.id) {
          storeData = [
            ...stores.slice(0, index),
            updatedRecord,
            ...stores.slice(index + 1)
          ];
        }
      }
      changes = {
        stores: storeData,
        editing: false,
        saving: false
      };
      return { ...state, ...changes };

    case 'STORE_DELETE_FULFILLED':
      Toastr.handleShow(true, response.meta.message, 201)
      stores = [...state.stores];
      for (const [index, store] of stores.entries()) {
        if (store.id === response.store.id) {
          stores.splice(index, 1);
        }
      }
      return {
        ...state,
        stores: stores,
        error: null
      };

    case 'STORE_EDIT':
      changes = {
        editing: {
          id: action.id,
        },
        store: { attributes: { address_attributes: {country: "United States"} } }
      };
      return { ...state, ...changes };

    case 'STORE_EDIT_CANCEL':
      changes = {
        editing: false
      };
      return { ...state, ...changes };


    default:
      return state;
  }
}